import { Actions, createEffect, ofType } from '@ngrx/effects';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, distinctUntilChanged, of, switchMap } from 'rxjs';
import { catchError, filter, map, take, tap } from 'rxjs/operators';
import { selectIsBoUser } from '@user/store/selectors/user.selectors';
import { authActions, boAuthActions } from '@mkp/auth/actions';
import { AppConfig } from '@config/app.config';
import { VacancyResource } from '@mkp/vacancy/data-access';
import { Router } from '@angular/router';
import { isNavigationEnd } from '@shared/helpers';
import { getMessageError } from '@core/models';

export const selectAccountOnVacancySearch = createEffect(
  (
    actions$ = inject(Actions),
    store = inject(Store),
    vacancyResource = inject(VacancyResource),
    router = inject(Router)
  ) =>
    actions$.pipe(
      ofType(authActions.userIsVerified),
      switchMap(() => combineLatest({ isBoUser: store.select(selectIsBoUser), url: currentUrl(router) })),
      filter(({ isBoUser, url }) => isBoUserFn(isBoUser) && isVacancySearchUrl(url)),
      switchMap(({ url }) =>
          vacancyResource.getById(getVacancyId(url))
            .pipe(
              filter(Boolean),
              switchMap(({ accountId }) =>
                of(boAuthActions.loadWorkspace({ accountId }))
              ),
              catchError((error: unknown) => {
                router.navigate([AppConfig.routes.dashboard]);
                return of(boAuthActions.loadWorkspaceError({ error: getMessageError(error, 'selectAccountOnVacancySearch') }))
              }
            ),
        )
      )
    ),
  { functional: true }
);

const currentUrl = (router: Router) => router.events.pipe(
  filter(isNavigationEnd),
  map((event) => event.urlAfterRedirects),
  distinctUntilChanged(),
  take(1)
);

const isBoUserFn = (isBoUser: boolean | null) => isBoUser !== null && isBoUser;
const isVacancySearchUrl = (url: string) => url.includes(`${AppConfig.routes.vacancy}?search=`);
const getVacancyId = (url: string) => url.split('search=')[1];