import { CompanyProfile } from '@company/models/company-profile.model';
import {
  companyApiActions,
  companyExistsGuardActions,
  companyFormPageActions,
} from '@company/store/actions';
import * as companyActions from '@company/store/actions/company.actions';
import { LegalEntityState } from '@mkp/legal-entity/data-access';
import { ApiLink } from '@mkp/shared/data-access';
import { FetchState, LoadingState } from '@mkp/shared/util-state';
import { Action, createReducer, on } from '@ngrx/store';
import { authActions, boAuthActions } from '@mkp/auth/actions';

const featureKey = 'companyState';
export interface CompanyState {
  profiles: { [id: string]: CompanyProfile };
  selectedId: string | undefined;
  fetchState: FetchState<{ message: string }>;
  totalCount?: number;
  _links?: { [key: string]: ApiLink };
  filter?: string;
}

/***** initial state  ****/
export const initialState: CompanyState = {
  profiles: {},
  selectedId: undefined,
  fetchState: LoadingState.INIT,
  totalCount: undefined,
  _links: undefined,
  filter: undefined,
};

/***** Profile *****/
const ResetCompanyProfile = (state: CompanyState) => {
  return { ...state, ...initialState };
};

/****** Update Profile *****/
const CompanyUpdateProfileSuccess = (
  state: CompanyState,
  profile: Partial<CompanyProfile>
): CompanyState => {
  if (profile?.id) {
    const companyProfile: CompanyProfile = { ...state.profiles[profile.id], ...profile };

    /**use entity pattern ***/
    const profiles: { [id: string]: CompanyProfile } = {
      ...state.profiles,
      [companyProfile.id]: companyProfile,
    };

    // Update profile
    return {
      ...state,
      fetchState: LoadingState.LOADED,
      profiles,
    };
  }

  // Update profile
  return {
    ...state,
    fetchState: LoadingState.LOADED,
  };
};

/****** Get Profiles List *****/
const CompanyGetProfileListSuccess = (
  state: CompanyState,
  results: CompanyProfile[],
  _links: { [key: string]: ApiLink },
  filter: string,
  totalCount: number
) => {
  const newState = upsertMany(state, results);

  // Update profile
  return {
    ...newState,
    fetchState: LoadingState.LOADED,
    _links,
    filter,
    totalCount,
  };
};

// TODO: use adapter instead of redefining these
const upsertMany = (state: CompanyState, companyProfiles: CompanyProfile[]): CompanyState => {
  const profiles = companyProfiles.reduce(
    (profiles, profile) => ({
      ...profiles,
      [profile.id]: profile,
    }),
    {
      ...state.profiles,
    }
  );

  return {
    ...state,
    profiles,
  };
};

const CompanyDeleteSuccess = (state: CompanyState, companyId: string) => {
  const profiles = Object.entries(state.profiles)
    .filter(([id]) => id !== companyId)
    .reduce((acc, [id, profile]) => ({ ...acc, [id]: profile }), {});
  return {
    ...state,
    fetchState: LoadingState.LOADED,
    profiles,
  };
};

const CompanyLocationDeleteSuccess = (
  state: CompanyState,
  { locationId, companyProfileId }: { locationId: string; companyProfileId: string }
) => {
  const locations =
    state.profiles[companyProfileId]?.locations?.filter((location) => location.id !== locationId) ??
    [];

  const profiles: { [id: string]: CompanyProfile } = {
    ...state.profiles,
    [companyProfileId]: { ...state.profiles[companyProfileId], locations },
  };

  return {
    ...state,
    fetchState: LoadingState.LOADED,
    profiles,
  };
};
const _companyReducer = createReducer(
  initialState,
  on(companyActions.ResetCompanyProfile, (state) => ResetCompanyProfile(state)),
  on(
    companyActions.CompanyGetProfileListSuccess,
    (state, { companyProfiles, _links, filter, totalCount }) =>
      CompanyGetProfileListSuccess(state, companyProfiles, _links, filter, totalCount)
  ),
  on(
    companyApiActions.loadCompanySuccess,
    companyApiActions.loadCompanyIfNotExistSuccess,
    companyApiActions.updateCompanySuccess,
    (state, { company }) => CompanyUpdateProfileSuccess(state, company)
  ),
  on(
    companyApiActions.loadCompaniesFromVacancySuccess,
    companyApiActions.loadCompaniesFromAccountSuccess,
    (state, { companyProfiles }) => upsertMany(state, companyProfiles)
  ),
  on(authActions.loadCompaniesAfterLoginSuccess, boAuthActions.loadWorkspaceCompanyLoadSuccess, (state, { companyProfiles }) =>
    upsertMany(
      {
        ...state,
        selectedId: companyProfiles.length === 1 ? companyProfiles[0].id : undefined,
        fetchState: LoadingState.LOADED,
      },
      companyProfiles
    )
  ),
  on(companyExistsGuardActions.canActivate, (state, { companyId }) =>
    state.profiles[companyId] ? state : { ...state, fetchState: LoadingState.LOADING }
  ),
  on(
    authActions.loadActiveAccountMembershipsSuccess,
    boAuthActions.loadWorkspaceAccountLoadSuccess,
    companyActions.CompanyActivate,
    companyActions.CompanyDelete,
    companyActions.CompanyGetProfileList,
    companyFormPageActions.updateCompany,
    companyFormPageActions.createLocation,
    companyFormPageActions.updateLocation,
    companyFormPageActions.deleteLocation,
    (state) => ({ ...state, fetchState: LoadingState.LOADING })
  ),
  on(companyApiActions.deleteLocationSuccess, (state, { id, companyProfileId }) =>
    CompanyLocationDeleteSuccess(state, { locationId: id, companyProfileId })
  ),
  on(
    authActions.loadAccountsAfterLoginFailed,
    boAuthActions.loadWorkspaceCompanyLoadError,
    companyActions.CompanyActivateFailure,
    companyActions.CompanyDeleteFailure,
    companyActions.CompanyGetProfileListFailure,
    companyApiActions.loadCompanyFailure,
    companyApiActions.loadCompanyIfNotExistFailure,
    companyApiActions.loadCompaniesFromVacancyFailure,
    companyApiActions.loadCompaniesFromAccountFailure,
    companyApiActions.updateCompanyFailure,
    companyApiActions.createCompanyLocationFailure,
    companyApiActions.updateCompanyLocationFailure,
    (state, { error }) => ({ ...state, fetchState: { error } })
  ),
  on(companyActions.CompanyActivateSuccess, (state, { companyId, legalEntityVersion }) =>
    CompanyUpdateProfileSuccess(state, {
      id: companyId,
      legalEntityState: LegalEntityState.Active,
      legalEntityVersion,
    })
  ),
  on(companyActions.CompanyDeleteSuccess, (state, { companyId }) =>
    CompanyDeleteSuccess(state, companyId)
  ),
  on(
    authActions.workspaceSelected,
    boAuthActions.workspaceSelected,
    (state, { companyProfileId }) => ({
      ...state,
      selectedId: companyProfileId,
    })
  ),
  on(boAuthActions.workspaceUnselected, (state) => ({
    ...state,
    selectedId: undefined,
  }))
);

function reducer(state: CompanyState, action: Action): CompanyState {
  return _companyReducer(state, action);
}

export { featureKey, reducer };
