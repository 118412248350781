import * as fromCompany from '@company/store/effects';
import { AuthApiEffects, authEffects, boAuthEffects } from '@mkp/auth/state';
import { BenefitsEffects } from './benefits.effects';
import { LanguageEffects } from './language.effects';
import { RouterEffects } from './router.effects';
import { SeoEffects } from './seo.effects';
import { SettingsEffects } from './settings.effects';

export const effects = [
  authEffects,
  boAuthEffects,
  RouterEffects,
  SettingsEffects,
  ...fromCompany.effects,
  BenefitsEffects,
  SeoEffects,
  AuthApiEffects,
  LanguageEffects,
];
