import { Routes } from '@angular/router';
import { AppConfig } from '@config/app.config';
import { ActiveTrafficFunnelGuard, OnboardingGuard } from '@core/guards';

export const visitorOnlyUrls = {
  home: AppConfig.routes.home,
  onboarding: AppConfig.routes.onboarding,
  onboardingFunnel: `${AppConfig.routes.onboarding}/:funnel`,
  login: AppConfig.routes.login,
  passwordlessLogin: AppConfig.routes.passwordlessLogin,
  passwordlessLoginLinkSent: AppConfig.routes.passwordlessLoginLinkSent,
  passwordlessLoginLinkExpired: AppConfig.routes.passwordlessLoginLinkExpired,
  resetPassword: AppConfig.routes.resetPassword,
};

export const visitorUrls = {
  cms: AppConfig.routes.cms,
  supportCenter: AppConfig.routes.supportCenter,
  ...visitorOnlyUrls,
};

export const visitorRoutes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('@mkp/layout/shell-user-layout').then((m) => m.ShellUserLayoutComponent),
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: visitorUrls.home,
      },
      {
        path: visitorUrls.home,
        data: { title: 'META_TITLE.HOME' },
        loadComponent: () => import('@mkp/core/feature-home').then((m) => m.HomeComponent),
        canActivate: [OnboardingGuard],
      },
      {
        path: visitorUrls.login,
        data: { title: 'META_TITLE.LOGIN' },
        loadComponent: () => import('@mkp/user/feature-login').then((m) => m.LoginComponent),
      },
      {
        path: visitorUrls.passwordlessLogin,
        data: { title: 'META_TITLE.LOGIN' },
        loadChildren: () =>
          import('@mkp/user/feature-passwordless-login').then(
            (m) => m.userFeaturePasswordlessLoginRoutes
          ),
      },
      {
        path: visitorUrls.resetPassword,
        data: { title: 'META_TITLE.RESETPASSWORD' },
        loadComponent: () =>
          import('@mkp/user/feature-reset-password').then((m) => m.ResetPasswordComponent),
      },
      {
        path: visitorUrls.onboarding,
        children: [
          {
            path: '',
            data: { title: 'META_TITLE.ONBOARDING' },
            loadComponent: () =>
              import('@mkp/onboarding/feature-onboarding').then((m) => m.OnboardingComponent),
          },
          {
            path: ':funnel',
            canActivate: [ActiveTrafficFunnelGuard],
            data: { title: 'META_TITLE.ONBOARDING' },
            loadComponent: () =>
              import('@mkp/onboarding/feature-onboarding').then((m) => m.OnboardingComponent),
          },
        ],
      },
      {
        path: visitorUrls.cms,
        children: [
          {
            path: ':slug',
            loadComponent: () =>
              import('@mkp/core/feature-cms').then((m) => m.CMSLandingPageComponent),
          },
          {
            path: '**',
            redirectTo: 'jobs-lg-ecom',
            pathMatch: 'prefix',
          },
        ],
      },
      {
        path: visitorUrls.supportCenter,
        data: { title: 'META_TITLE.SUPPORT_CENTER' },
        loadComponent: () =>
          import('@mkp/core/feature-support-center').then((m) => m.SupportCenterComponent),
      },
    ],
  },
  { path: '**', redirectTo: visitorUrls.home },
];
